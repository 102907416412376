@import '../../variables.scss';

.backbutton{
  padding: 0 columns(1);
  margin-bottom: 1em;

  a {
    text-transform: uppercase;
    font-size: 1.3rem;
    color: $dark-blue-color;
    font-weight: 700;
    text-decoration: none;
    display: flex;
    align-items: center;
    svg{
      max-width:30px;
    }
    &:visited{
      color: $dark-blue-color;
    }
    span {
      margin-left: .5rem;
    }
  }
}
