$large-breakpoint: 1200px;
$medium-breakpoint: 900px;
$small-breakpoint: 600px;

$wp-admin-bar-breakpoint: 783px;
$wp-admin-bar-height-mobile: 46px;
$wp-admin-bar-height: 32px;

$horizontal-padding-mobile: 1em;
$horizontal-padding: 2em;

$default-vertical-margin-mobile: 6rem;
$default-vertical-margin: 10rem;

$pink-blob: #F5A4C7;


$white-color: #ffffff;
$pink-color: #F4D9E5;
$purple-color: #D3CAE4;
$dark-purple-color: #937CB9;
$yellow-color:#F7DE03;
$light-red-color: #EF8771;
$light-green-color: #A5C74C;
$dark-green-color: #A5C74C;

$dark-blue-color: #0063A3;
$blue-bg-color:#D9EDEB;
$blue-color: #82BEFA;;
$light-blue-color: #AADFF1;
$blue-color-inbetween: #36AABD;
$grey-color:#DCDDDE;
$black-color: #000D20;
$body-bg-color: $white-color;
$text-color: $black-color;
$link-color: #000;
$line-height: 1.2;
$font-weight: 400;

$default-font: diamondgirlmedium, 'Open Sans', sans-serif;
$skinny-title-font: diamondgirlskinny, 'Open Sans', sans-serif;
$body-font-size-mobile: 20px;
$body-font-size: 20px;

$title-font-size-xsmall-mobile: 0.875rem;
$title-font-size-xsmall: 0.833rem;

$title-font-size-small-mobile: 1.25rem;
$title-font-size-small: 1.333rem;

$title-font-size-medium-mobile: 1.5rem;
$title-font-size-medium: 2rem;

$title-font-size-large-mobile:  2rem;
$title-font-size-large: 3rem;

$title-font-size-xlarge-mobile: 2.625rem;
$title-font-size-xlarge: 4rem;

$border-radius-small: 8px;
$border-radius-medium: 24px;
$border-radius-large: 32px;


$base-breakpoint: 1440px;
$column-width: percentage(48px/$base-breakpoint);
$column-gutter: percentage(48px/$base-breakpoint);
$column-total-width: $column-width + $column-gutter;

$default-animation-function: cubic-bezier(0.645, 0.045, 0.355, 1);

@mixin default-padding() {
  padding-left: $horizontal-padding-mobile;
  padding-right: $horizontal-padding-mobile;

  @media (min-width: $small-breakpoint) {
    padding-left: $horizontal-padding;
    padding-right: $horizontal-padding;
  }
}

@mixin body-font-size() {
  font-size: $body-font-size-mobile;

  @media (min-width: $medium-breakpoint) {
    font-size: $body-font-size;
  }
}

@mixin title-font-size-xsmall() {
  font-size: $title-font-size-xsmall-mobile;
  font-weight: $font-weight;


  @media (min-width: $medium-breakpoint) {
    font-size: $title-font-size-xsmall;
  }
}

@mixin title-font-size-small() {
  font-size: $title-font-size-small-mobile;
  font-weight: $font-weight;

  @media (min-width: $medium-breakpoint) {
    font-size: $title-font-size-small;
  }
}

@mixin title-font-size-medium() {
  font-size: $title-font-size-medium-mobile;
  font-weight: $font-weight;

  @media (min-width: $medium-breakpoint) {
    font-size: $title-font-size-medium;
  }
}

@mixin title-font-size-large() {
  font-size: $title-font-size-large-mobile;
  font-weight: $font-weight;

  @media (min-width: $medium-breakpoint) {
    font-size: $title-font-size-large;
  }
}

@mixin title-font-size-xlarge() {
  font-size: $title-font-size-xlarge-mobile;
  font-weight: $font-weight;

  @media (min-width: $medium-breakpoint) {
    font-size: $title-font-size-xlarge;
  }
}

@mixin default-vertical-margin() {
  margin-top: $default-vertical-margin-mobile;
  margin-bottom: $default-vertical-margin-mobile;

  @media (min-width: $small-breakpoint) {
    margin-top: $default-vertical-margin;
    margin-bottom: $default-vertical-margin;
  }
}

@mixin flush-horizontal-edges() {
  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

@mixin flush-vertical-edges() {
  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

@mixin absolute-full-size() {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}

@mixin breakpoint($point) {
  @media (min-width: $point) {
    @content;
  }
}

@mixin border-radius($size){
  border-radius: $size;
}
//mixin for color on bg-image for sub nav
@mixin svg-element-with-color($color) {
//-webkit-mask-image: url('../../images/misc/nav-bg.svg');
  background-color: $color;

}


/* @function columns($count) {
  @return $column-width * $count + $column-gutter * ($count - 1);
} */

@function columns($count) {
  @return $column-width * $count;
}

@function gutter($count) {
  @return $column-gutter * $count;
}
