@import '../../variables.scss';

.three-step-model {

  .image-row {
    display: flex;
    justify-content: space-between;
    padding: 0 columns(2);
  }

  .image-wrapper {
    text-align: center;
    margin-right: 1em;
    width: 31%;

    &:last-child {
      margin-right: 2%;
    }

    img {
      display: block;
      max-width: 100%;
      height: auto;
      margin: 0 auto;
    }
  }
}
