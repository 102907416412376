@import 'variables.scss';

.App{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;


}
.content-wrapper {
  margin: 0 auto;
  max-width:768px;
  min-height:100vh;
}

.page{
  padding-top:2rem;
  min-height: 100vh;
  .videoWrapper{
    max-width: 100%;
    display: flex;
  }
  .intermission-image{
    max-width:1440px;
    display: flex;
    padding: 0 columns(2);
    img{
      width: 100%;
    }
  }
  h1{
    margin:1rem 2rem;
    text-align: center;
    font-family:'BradyBunch' ,sans-serif;
    font-weight: 400;
  }
  .imageWrapper{
    max-width: 100%;
    display: flex;
    margin: 1rem 0;
  img{
    margin: 0 auto;
  }
  }
  .sound-heading{
    padding: 0 columns(2);
    font-family:'diamondgirlmedium',sans-serif;
    font-weight: 600;
    font-size:1.3rem;
    text-align: center;
    margin-top:1.5rem;
  }
  .top-image-wrapper{
    padding: 0 columns(4);
  img, svg {
    width: 100%;
    height: auto;
    margin-bottom: 2rem;
    }
  }
}
