@import '../../variables.scss';

.sub-nav{

  ul{
    list-style: none;
    display:flex;
    flex-wrap: wrap;
    padding:0;
    width:100%;
    li{
      display:flex;
      width:45%;
      margin: 0.5rem auto;
      text-align: center;
      align-items: center;
      height: 100%;

      a{
        width: 100%;
        height: 100%;
        display:flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        text-decoration: none;
        color: $text-color;
        background-color: $pink-blob;
        font-size: 1.1em;
        padding: 5rem 1rem;
        img{
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left:0;
          bottom: 0;
          right: 0;
        }
        span{
          z-index: 200;
        }
      }
    }
  }
}
