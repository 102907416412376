@import '../../variables.scss';
.home {
  background-color:#AADFF1;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  min-height: 100vh;
  padding: 0 columns(1);
  #logo{
    margin: 2rem 0;
    justify-content: center;
    display: flex;
  }
  .logoWrapper{
    display: flex;
    flex-direction:column;
    align-items: center;
    img{
      margin-bottom: 1.5rem;
    }
  }

  .videoWrapper{
    margin: 0 auto;
    iframe{
      max-width: 100%;
    }
  }
  .login-form{
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
    text-align:center;
    padding: columns(1) 0;
    p{
      margin: 1rem auto 0.5rem auto;
      text-align: center;
      font-size: 1.5rem;
    }
    .input-wrapper{
      display: flex;
      position: relative;
      svg{
        width: 100%;
        height: auto;
      }
      input{
        position: absolute;
        width: 94%;
        left: 3%;
        height: 40%;
        top: 24%;
        background: transparent;
        border: none;
        text-align: center;
        font-size: 1.5em;
      }

      &.login-error {
        svg path {
          fill: $light-red-color;
        }
      }
    }
    .submit-btn{
      background-size: contain;
      border: none;
      background-color:transparent;
      padding: 1rem 2rem;
      background-repeat: no-repeat;
      background-position: center center;
      font-size:1.5rem;
      margin-bottom: 2rem;
      font-family: 'diamondgirlmedium', sans-serif;
    }
  }
  h1 {
    font-size: 2em;
  }
  p{
    max-width: 50ch;
    text-align: left;
  }
}
