@import '../../variables.scss';

.breathing-excersise{
  position:relative;
  margin: 2rem 0;
  .bonus{
    text-align: center;
  }
  .breathing-image{
    padding: 0 4rem;
  }
  .control-wrapper{
    position:absolute;
    right: 60px;
    bottom:40px;
  }
  .intro-content{
    text-align: center;
    margin: 0 4rem;
  }
}
