@import '../../variables.scss';
.text-list{
  padding: 0 columns(2);
  ul{
    list-style: none;
    padding: 0;
    li{
      display: flex;
      justify-content: flex-start;
      margin: 2rem 0;
      p{
        margin:0;
        font-family: 'diamondgirlskinny',sans-serif;
        font-weight: 600;
        max-width:50ch;
      }
      .item-number{
        font-size:3rem;
        color:white;
        font-weight:700;
        padding-right: 1.5rem;
        font-family: 'BradyBunch',sans-serif;
        line-height:0.8;
      }
    }
  }
}