@import "variables";

p{
  @include body-font-size();
  font-family: diamondgirlmedium, 'Open Sans' ,sans-serif;
}

h1{
  @include title-font-size-xlarge()
}

h2 {
  @include title-font-size-large();
}

h3{
  @include title-font-size-medium();
}

h4{
  @include title-font-size-small();
}

h5{
  @include title-font-size-xsmall();
}



